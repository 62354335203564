var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['message-box', 'my-2', _vm.isSender(_vm.message) && 'owner'],attrs:{"id":("message-" + (_vm.message.id))}},[(_vm.firstMessage && _vm.firstMessage.id === _vm.message.id && _vm.firstLoad)?_c('div',{staticClass:"strike small text-muted pb-2"},[_vm._v(" 對話開始 ")]):(_vm.firstNewMessage && _vm.firstNewMessage.id === _vm.message.id)?_c('div',{staticClass:"strike small text-muted pb-2"},[_vm._v(" 最新訊息 ")]):(_vm.firstUnreadMessage && _vm.firstUnreadMessage.id === _vm.message.id)?_c('div',{staticClass:"strike small text-muted pb-2"},[_vm._v(" 未讀 ")]):_vm._e(),(_vm.checkStrike())?_c('div',{staticClass:"strike small text-muted pb-2"},[_vm._v(" "+_vm._s(_vm.formatDateD)+" ")]):_vm._e(),_c('div',{staticClass:"message-wrapper"},[_c('div',{staticClass:"d-flex align-items-start"},[_c('div',[_c('b-avatar',{staticClass:"mr-2",attrs:{"src":_vm.message.sender.avatar_url}}),_c('small',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.messageSenderName)+" ")]),_c('div'),(_vm.message.type === 'image' && _vm.message.meta.images.length > 0)?[_c('div',{staticClass:"message image",class:{
              multiple: _vm.message.meta.images && _vm.message.meta.images.length > 1,
            }},_vm._l((_vm.message.meta.images),function(image,i){return _c('div',{key:i,staticClass:"image-wrapper",class:{ expanded: _vm.message.meta.images.length & 1 && i === 0 }},[(image.previewImageUrl)?_c('img',{attrs:{"src":image.previewImageUrl},on:{"click":function($event){_vm.showImageLightBox(_vm.getLightBoxSrcs(_vm.message), i)}}}):_vm._e(),(_vm.showRecall && _vm.isSender(_vm.message) && _vm.afterCanRecallHours())?_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","name":"selectRecallMessage","id":'selected_message_checkbox_' +
                    image.id +
                    image.previewImageUrl,"disabled":_vm.messageRecalling},domProps:{"value":image.id,"checked":_vm.checked(image.id)},on:{"change":_vm.selected}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'selected_message_checkbox_' +
                    image.id +
                    image.previewImageUrl}})]):_vm._e()])}),0)]:(
            _vm.message.type === 'file' && _vm.message.content != '(訊息已收回)'
          )?_c('div',{staticClass:"message file py-2 px-3"},[_c('a',{attrs:{"href":_vm.message.meta.fileUrl,"target":"_blank"}},[_c('div',{staticClass:"icon"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 384 512","fill":"currentColor"}},[_c('path',{attrs:{"d":"M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z"}})])]),_c('div',{staticClass:"info"},[_c('p',[_vm._v(_vm._s(_vm.message.meta.fileName))]),_c('p',[_vm._v(_vm._s(_vm.filesizeForHuman(_vm.message.meta.fileSize)))])])])]):(_vm.message.type === 'sticker' && _vm.message.content != '(訊息已收回)')?_c('div',{staticClass:"message sticker py-2 px-3"},[_c('img',{attrs:{"src":_vm.message.meta.stickerUrl}})]):_c('div',{staticClass:"message py-2 px-3",class:_vm.message.draft ? 'draft-message' : 'message',domProps:{"innerHTML":_vm._s(_vm.manipulateMessage(_vm.message.content))}})],2)]),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-1"},[(
          _vm.message.type === 'image' &&
          _vm.showMultiDownload &&
          _vm.message.meta.images.length > 0
        )?_c('div',{staticClass:"image-download-section"},[_c('div',[_c('a',{staticClass:"download-all ml-2",staticStyle:{"display":"flex"},on:{"click":function($event){$event.preventDefault();_vm.selectedImageUrls = _vm.message.meta.images.map(
                function (image) { return image.originalContentUrl; }
              );
              _vm.downloadMultiImages();}}},[_c('div',[_c('svg',{staticStyle:{"width":"14px","height":"14px","margin-right":"10px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"}})])]),_c('div',[_vm._v("立即下載")])])])]):_vm._e(),_c('div',{staticClass:"\n          small\n          text-muted\n          d-flex\n          align-items-end\n          justify-content-end\n          ml-auto\n        "},[(
            _vm.showRecall &&
            _vm.isSender(_vm.message) &&
            _vm.message.content != '(訊息已收回)' &&
            _vm.message.type !== 'image' &&
            _vm.afterCanRecallHours()
          )?_c('div',{staticClass:"mt-1 custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","name":"selectRecallMessage","id":'selected_message_checkbox_' + _vm.message.id,"disabled":_vm.messageRecalling},domProps:{"value":_vm.message.id,"checked":_vm.checked(_vm.message.id)},on:{"change":_vm.selected}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'selected_message_checkbox_' + _vm.message.id}})]):_vm._e(),_c('ul',{staticClass:"list-unstyled read-list"},[_vm._l((_vm.senders),function(sender){return [(_vm.senderIsRead(sender, _vm.message))?_c('li',{key:sender.id,attrs:{"title":sender.name}},[_c('b-avatar',{attrs:{"size":"1rem","src":sender.avatar_url}})],1):_vm._e()]})],2),(!_vm.message.draft)?_c('time',[_vm._v(_vm._s(_vm.format(new Date(_vm.message.created_at), "HH:mm")))]):_vm._e()])])]),_c('ImageLightBox',{ref:"lightbox"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }